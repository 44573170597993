import { Injectable, OnDestroy } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BaseService } from '@app/shared/base/services';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable()
export class LoadingBarService extends BaseService implements OnDestroy {
  private _incTimeout: any;

  private _value = 0;

  navigationActive: boolean = false;

  pendingRequests = 0;

  readonly progress$ = new Subject<number>().pipe(debounceTime(0)) as Subject<number>;

  private navigationWasCanceled: boolean = false;

  constructor(private router: Router) {
    super();
  }

  start() {
    const initialValue = 2;
    ++this.pendingRequests;
    if (this._value === 0 || this.pendingRequests === 1) {
      // Inserts the loading bar element into the dom, and sets it to 2%
      this.set(this.pendingRequests === 1 && this._value > 0 ? this._value : initialValue);
    }
  }

  stop() {
    this.complete();
    while (this.pendingRequests > 0) {
      this.complete();
    }
  }

  untrackRouting() { }

  trackRouting() {
    super.addSubscription(
      this.router.events.subscribe((event: any): void => {
        if (event instanceof NavigationStart) {
          if (this._value === 0 || this._value === 100) {
            this.navigationActive = true;
            this.start();
          } else {
            // navigation was canceld
            this.navigationWasCanceled = true;
          }
        }
        if (event instanceof NavigationEnd) {
          setTimeout(() => {
            this.navigationActive = false;
            if (this.pendingRequests <= 1) {
              this.complete();
            }
          }, 200);
        }
        if (event instanceof NavigationCancel || event instanceof NavigationError) {
          this.navigationActive = false;
          this.complete();
        }
      })
    );
  }

  complete(navigationCanceled: boolean = false) {
    if (navigationCanceled === true) {
      --this.pendingRequests;
      --this.pendingRequests;
      return;
    }
    if (this.navigationActive) {
      --this.pendingRequests;
      return;
    }

    if (this.pendingRequests === 0 && this._value === 0) {
      return;
    }

    if (this.pendingRequests > 0) {
      --this.pendingRequests;
    }

    if (this.pendingRequests === 0 || (this.pendingRequests === 0 && this._value > 0)) {
      if (this._value !== 100) {
        this.set(100);
      }

      // Attempt to aggregate any start/complete calls within 500ms:
      setTimeout(() => this.set(0), 500);
    }
    if (!this.navigationActive && (this.pendingRequests === 1 || (this.pendingRequests === 1 && this._value > 0))) {
      this.pendingRequests = 0;
      if (this._value !== 100) {
        this.set(100);
      }
      this.complete();
    }
  }

  /**
   * Set the loading bar's width to a certain percent.
   *
   * @param n any value between 0 and 100
   */
  set(n: number) {
    if (n === 0 && this.pendingRequests > 0) {
      n = 2;
    }

    this._value = n;
    this.progress$.next(n);

    if (this.pendingRequests === 0) {
      return;
    }

    // increment loadingbar to give the illusion that there is always
    // progress but make sure to cancel the previous timeouts so we don't
    // have multiple incs running at the same time.
    clearTimeout(this._incTimeout);
    if (this._value > 0 && this._value < 100) {
      this._incTimeout = setTimeout(() => this.increment(), 150);
    }
  }

  /**
   * Increments the loading bar by a random amount
   * but slows down as it progresses
   */
  increment(rnd: number = 0) {
    if (rnd > 0) {
      this.set(this._value + rnd);
    }

    const stat = this._value;
    if (stat >= 0 && stat < 25) {
      // Start out between 3 - 6% increments
      rnd = Math.random() * (5 - 3 + 1) + 3;
    } else if (stat >= 25 && stat < 65) {
      // increment between 0 - 3%
      rnd = Math.random() * 3;
    } else if (stat >= 65 && stat < 90) {
      // increment between 0 - 2%
      rnd = Math.random() * 2;
    } else if (stat >= 90 && stat < 99) {
      // finally, increment it .5 %
      rnd = 0.5;
    } else {
      // after 99%, don't increment:
      rnd = 0;
    }

    this.set(this._value + rnd);
  }

  ngOnDestroy() {
    this.progress$.complete();
  }
}
