import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudioPlayerControlComponent } from './audio-player-control.component';
import { SharedModule } from '@app/shared/shared.module';
import { AudioPlayerModule } from '../audio-player/audio-player.module';
import { MetricsService } from '@app/shared/services/metrics.service';

@NgModule({
  imports: [
    SharedModule,

    AudioPlayerModule
  ],
  declarations: [AudioPlayerControlComponent],
  exports: [AudioPlayerControlComponent],
  providers: [MetricsService]
})
export class AudioPlayerControlModule { }
