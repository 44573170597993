import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@app/shared/base/services';
import { __ } from '@app/shared/functions/object.functions';
import { Episode } from '@app/shared/models/classes/Episode';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export class Queue {
    items: QueueItem[] = [];
}

export class QueueItem {
    episode: Episode;
    src: string;
    isPlaying: boolean = false;
}

@Injectable()
export class AudioPlayerService extends BaseService {

    private queue: Queue;

    private _queue$: Subject<Queue> = new Subject<Queue>();

    queue$: Observable<Queue> = this._queue$.asObservable();

    private isControlsVisible: boolean;

    private _isControlsVisible$: Subject<boolean> = new Subject<boolean>();

    isControlsVisible$: Observable<boolean> = this._isControlsVisible$.asObservable();

    isPlaying: QueueItem;

    private _isPlaying$: Subject<QueueItem> = new Subject<QueueItem>();

    isPlaying$: Observable<QueueItem> = this._isPlaying$.asObservable();

    constructor(protected httpClient: HttpClient) {
        super();

        this.queue = Object.assign(new Queue(), {});
    }

    add(item: QueueItem): void {
        this.queue.items.push(item);
        this._queue$.next(this.queue);
    }

    play(item?: QueueItem): void {
        const currentItem = this.queue.items[0];

        if (!__.IsNullOrUndefined(item)) {
            if (!__.IsNullOrUndefined(currentItem)) {
                currentItem.isPlaying = false;
            }

            this.queue.items.splice(0, 1, item);
            item.isPlaying = true;
            this.isPlaying = item;
            this._isPlaying$.next(item);

            this._queue$.next(this.queue);
        } else {
            if (!__.IsNullOrUndefined(currentItem)) {
                currentItem.isPlaying = true;
                this.isPlaying = currentItem;
                this._isPlaying$.next(currentItem);
            }
        }

        this._isControlsVisible$.next(true);
    }

    next(item: QueueItem): void {
        this.queue.items.splice(1, 0, item);
        this._queue$.next(this.queue);
    }

    pause(): void {
        const currentItem = this.queue.items[0];

        if (!__.IsNullOrUndefined(currentItem)) {
            currentItem.isPlaying = false;
            this.isPlaying = currentItem;
            this._isPlaying$.next(currentItem);
        }
    }
}
