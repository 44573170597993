import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'hypecast-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {

    @Input() templateRef: TemplateRef<any>;

    private _isLoading: boolean;

    @Input()
    get isLoading() {
        return this._isLoading;
    }
    set isLoading(value: boolean) {
        this._isLoading = value;
    }

    private _cssClass: string;

    @Input()
    get cssClass() {
        return this._cssClass;
    }
    set cssClass(value: string) {
        this._cssClass = value;
    }

    private _options: { width: string, height: string, marginTop: string, marginBottom: string };

    @Input()
    get options(): { width: string, height: string, marginTop: string, marginBottom: string } {
        return this._options;
    }
    set options(value: { width: string, height: string, marginTop: string, marginBottom: string }) {
        this._options = value;
    }

    constructor() { }
}
