import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MetricsCreateOptions } from "../models/create/MetricsCreateOptions";
import { EntityService } from "./entity.service";
import { Response } from "./local/Response";

@Injectable()
export class MetricsService extends EntityService<MetricsCreateOptions, MetricsCreateOptions, MetricsCreateOptions> {
  constructor(protected httpClient: HttpClient) {
    super("metrics", "Metric", httpClient);
  }

  createNewListen(episodeId: string, value: string): Observable<boolean> {
    return this.httpClient
      .skipErrorHandler()
      .post<Response<boolean>>(`metrics`, {
        episodeId,
        action: "listen",
        value,
      })
      .pipe(
        map((response: Response<boolean>) => {
          return response.code.statusCode === 200;
        })
      );
  }

  createNewListenFromPlugin(episodeId: string, value: string, token: string = null): Observable<boolean> {
    const headers = { Authorization: "Bearer " + token };
    return this.httpClient
      .skipErrorHandler()
      .disableAccessToken()
      .post<Response<boolean>>(
        `metrics`,
        {
          episodeId,
          action: "listen",
          value,
        },
        { headers }
      )
      .pipe(
        map((response: Response<boolean>) => {
          return response.code.statusCode === 200;
        })
      );
  }

  createNewListenFromIFramePlugin(episodeId: string, value: string, iframePluginUserGUID: string): Observable<boolean> {
    const headers = { "X-Hypecast-IFrame-Plugin-User-Id": iframePluginUserGUID };

    return this.httpClient
      .skipErrorHandler()
      .disableAccessToken()
      .post<Response<boolean>>(
        `metrics/iframe`,
        {
          episodeId,
          action: "listen",
          value,
        },
        { headers }
      )
      .pipe(
        map((response: Response<boolean>) => {
          return response.code.statusCode === 200;
        })
      );
  }

  createNewStream(episodeId: string): Observable<boolean> {
    return this.httpClient
      .skipErrorHandler()
      .post<Response<boolean>>(`metrics`, {
        episodeId,
        action: "stream",
        value: "",
      })
      .pipe(
        map((response: Response<boolean>) => {
          return response.code.statusCode === 200;
        })
      );
  }

  createNewStreamFromPlugin(episodeId: string, token: string = null): Observable<boolean> {
    const headers = { Authorization: "Bearer " + token };

    return this.httpClient
      .skipErrorHandler()
      .disableAccessToken()
      .post<Response<boolean>>(
        `metrics`,
        {
          episodeId,
          action: "stream",
          value: "",
        },
        { headers }
      )
      .pipe(
        map((response: Response<boolean>) => {
          return response.code.statusCode === 200;
        })
      );
  }

  createNewStreamFromIFramePlugin(episodeId: string, iframePluginUserGUID: string): Observable<boolean> {
    const headers = { "X-Hypecast-IFrame-Plugin-User-Id": iframePluginUserGUID };

    return this.httpClient
      .skipErrorHandler()
      .disableAccessToken()
      .post<Response<boolean>>(
        `metrics/iframe`,
        {
          episodeId,
          action: "stream",
          value: "",
        },
        { headers }
      )
      .pipe(
        map((response: Response<boolean>) => {
          return response.code.statusCode === 200;
        })
      );
  }
}
