import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { AudioPlayerService } from '@app/shared/components/audio-player-control/audio-player.service';
import { ForbiddenService } from '@app/shared/services/forbidden.service';

@Component({
  selector: 'hypecast-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss']
})
export class BaseLayoutComponent extends BaseComponent implements OnInit {

  isForbidden: boolean = false;

  isControlsVisible: boolean = false;

  constructor(
    private forbiddenService: ForbiddenService,
    private audioPlayerService: AudioPlayerService
  ) {
    super();
  }

  ngOnInit() {
    super.addSubscription(
      this.audioPlayerService.isControlsVisible$.subscribe((isVisible: boolean) => {
        this.isControlsVisible = isVisible;
      })
    )

    super.addSubscription(
      this.forbiddenService
        .isForbidden$
        .subscribe((isForbidden: boolean) => {
          this.isForbidden = isForbidden;
        })
    );
  }

}
