import { NgModule } from "@angular/core";
import { AudioPlayerComponent } from "./audio-player.component";
import { MinuteSecondsPipeModule } from "@app/shared/pipes/minute-seconds.module";
import { MinuteSecondsPipe } from "@app/shared/pipes/minute-seconds.pipe";
import { MatSliderModule } from "@angular/material/slider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { SharedModule } from "@app/shared/shared.module";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ImageModule } from "@app/shared/components/image/image.module";
import { FilesService } from "@app/shared/services/files.service";

@NgModule({
  imports: [
    SharedModule,

    MinuteSecondsPipeModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    ImageModule,
  ],
  providers: [MinuteSecondsPipe, FilesService],
  declarations: [AudioPlayerComponent],
  exports: [AudioPlayerComponent],
})
export class AudioPlayerModule {}
