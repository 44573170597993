import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";

import { HttpCacheService } from "./http-cache.service";
import { __ } from "@app/shared/functions/object.functions";
import * as moment from "moment";

export class CacheSettings {
  lastUpdated?: Date;
  expires?: number | Date; // seconds | date
  update?: boolean;
}

/**
 * Caches HTTP requests.
 * Use ExtendedHttpClient fluent API to configure caching for each request.
 */
@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private settings: CacheSettings;

  private forceUpdate = false;

  constructor(private httpCacheService: HttpCacheService) {
    httpCacheService.setPersistence("local");
  }

  /**
   * Configures interceptor options
   * @param options If update option is enabled, forces request to be made and updates cache entry.
   * @return {CacheInterceptor} The configured instance.
   */
  configure(options?: CacheSettings | null): CacheInterceptor {
    const instance = new CacheInterceptor(this.httpCacheService);
    if (!__.IsNullOrUndefined(options)) {
      if (!__.IsNullOrUndefined(options.expires)) {
        options.expires = isNaN(options.expires as any)
          ? options.expires
          : moment()
              .add(options.expires as number, "seconds")
              .toDate();
      }
      instance.settings = options;
      instance.forceUpdate = !__.IsNullOrUndefined(options.update) ? options.update : false;
    }
    return instance;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const urlExclusionPattern =
      /\/api\/v1\/files\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\.\w+\/urls/;

    // HYPE-897: Don't cache non-GET requests except it's a POST request to generate signedUrls.
    // Reason: This POST endpoint /api/v1/files/{guid}.{ext}/urls generates a new signed URL for
    // the file every time it is called.
    if (request.method !== "GET" && !urlExclusionPattern.test(request.url)) {
      return next.handle(request);
    }

    return new Observable((subscriber: Subscriber<HttpEvent<any>>) => {
      const cachedData = this.forceUpdate ? null : this.httpCacheService.getCacheData(request.urlWithParams);
      if (cachedData !== null) {
        // Create new response to avoid side-effects
        // tslint:disable-next-line:ban-types
        subscriber.next(new HttpResponse(cachedData as Object));
        subscriber.complete();
      } else {
        next.handle(request).subscribe(
          (event) => {
            if (event instanceof HttpResponse) {
              this.httpCacheService.setCacheData(
                request.urlWithParams,
                event,
                this.settings?.lastUpdated,
                this.settings?.expires as Date
              );
            }
            subscriber.next(event);
          },
          (error) => subscriber.error(error),
          () => subscriber.complete()
        );
      }
    });
  }
}
