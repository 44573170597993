export enum AppRoute {
  Podcasts = "podcasts",
  Groups = "groups",
  Episodes = "episodes",
  Profile = "profile",
  Discover = "discover",
  Home = "home",
  Forbidden = "forbidden",
  Settings = "settings",

  IframePlugin = "iframe-plugin",
  IframePlayer = "iframe-player",
  IframeLibrary = "iframe-library",
  IframePodcast = "iframe-podcast",

  StaffbasePlugin = "staffbase-plugin",
  StaffbasePluginEditor = "editor",
  StaffbasePluginUser = "user",
  StaffbasePluginPodcasts = "plugin-podcasts",

  CoyoPlugin = "coyo-plugin",
  CoyoPlayer = "coyo-player",
  CoyoLibrary = "coyo-library",
  CoyoPodcast = "coyo-podcast",
}

export class AppRouteTranslationMap {
  static Translations = {
    users: "Users",
    podcasts: "Podcasts",
    register: "register",
  };
}
