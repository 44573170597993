import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Response } from './local/Response';
import { map } from 'rxjs/operators';
import { BaseService } from '../base/services';
import { __ } from '../functions/object.functions';
import { CacheSettings } from '@app/core/http/cache.interceptor';

export class EntityService<T, TCreate, TUpdate> extends BaseService {
  constructor(
    private endpoint: string,
    private entity: string,
    protected httpClient: HttpClient
  ) {
    super();
  }

  getAll(
    queryParameters: string = '',
    skip: number = 0,
    take: number = 20,
    cacheOptions: CacheSettings = null
  ): Observable<T[]> {
    const _skip = __.IsNullOrUndefined(skip) ? 0 : skip;
    const _take = __.IsNullOrUndefined(take) ? 20 : take;
    const _queryParameters = __.IsNullOrUndefinedOrEmpty(queryParameters) ? '' : `&${queryParameters}`;

    let httpClient = this.httpClient;
    if (!__.IsNullOrUndefined(cacheOptions)) {
      httpClient = httpClient.cache(cacheOptions, false);
    } else {
      httpClient = httpClient.cache(null, false);
    }

    return httpClient.get<Response<T[]>>(`${this.endpoint}?skip=${_skip}&take=${_take}${_queryParameters}`).pipe(
      map((response: Response<T[]>) => {
        return response.data;
      })
    );
  }

  getById(
    id: string,
    cacheOptions: CacheSettings = null
  ): Observable<T> {

    let httpClient = this.httpClient;
    if (!__.IsNullOrUndefined(cacheOptions)) {
      httpClient = httpClient.cache(cacheOptions, false);
    } else {
      httpClient = httpClient.cache(null, false);
    }

    return httpClient.get<Response<T>>(`${this.endpoint}/${id}`).pipe(
      map((response: Response<T>) => {
        return response.data;
      })
    );
  }

  getAllChildren<TChild>(
    id: string,
    childEndpoint: string,
    queryParameters: string = '',
    skip: number = 0,
    take: number = 20,
    cacheOptions: CacheSettings = null
  ): Observable<TChild[]> {
    const _skip = __.IsNullOrUndefined(skip) ? 0 : skip;
    const _take = __.IsNullOrUndefined(take) ? 20 : take;
    const _queryParameters = __.IsNullOrUndefinedOrEmpty(queryParameters) ? '' : `&${queryParameters}`;

    let httpClient = this.httpClient;
    if (!__.IsNullOrUndefined(cacheOptions)) {
      httpClient = httpClient.cache(cacheOptions, false);
    } else {
      httpClient = httpClient.cache(null, false);
    }

    return this.httpClient
      .skipErrorHandler()
      .get<Response<TChild[]>>(`${this.endpoint}/${id}/${childEndpoint}?skip=${_skip}&take=${_take}${_queryParameters}`)
      .pipe(
        map((response: Response<TChild[]>) => {
          return response.data;
        })
      )
  }

  create(entity: TCreate): Observable<T> {
    return this.httpClient.post<Response<T>>(`${this.endpoint}`, entity).pipe(
      map((response: Response<T>) => {
        return response.data;
      })
    );
  }

  update(id: string, entity: TUpdate): Observable<T> {
    return this.httpClient.patch<Response<T>>(`${this.endpoint}/${id}`, entity).pipe(
      map((response: Response<T>) => {
        return response.data;
      })
    );
  }

  deleteById(id: string): Observable<boolean> {
    return this.httpClient.delete<Response<boolean>>(`${this.endpoint}/${id}`).pipe(
      map((response: Response<boolean>) => {
        return response.data;
      })
    );
  }
}
