import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subscriber } from "rxjs";
import { map } from "rxjs/operators";
import { HypecastAuthenticationService } from "../authentication/services/authentication.service";

import { BaseService } from "../base/services";
import { FileContainerBase } from "../components/files/models/FileContainer";
import { FileState } from "../components/files/models/FileState.enum";
import { __ } from "../functions/object.functions";
import { DatabaseFile } from "../models/classes/File";
import { Response } from "./local/Response";
import { ApiUrlService } from "@app/shared/services/api-url.service";
import { CacheSettings } from "@app/core/http/cache.interceptor";
import { Logger } from "@app/core/logger.service";

const log = new Logger("HttpCacheService");

@Injectable()
export class FilesService extends BaseService {
  constructor(
    private httpClient: HttpClient,
    private authenticationService: HypecastAuthenticationService,
    private apiUrlService: ApiUrlService
  ) {
    super();
  }

  downloadFileAsBlob(fileId: string): Observable<Blob> {
    return this.downloadFile(fileId, "Blob");
  }

  downloadFile(
    fileId: string,
    convertTo: "any" | "Uint8Array" | "Blob" = "Uint8Array"
  ): Observable<any | Uint8Array | Blob> {
    const headers = new HttpHeaders();
    headers.set("Authorization", `Bearer ${this.authenticationService.getAccessToken()}`);

    const options: {
      headers?: HttpHeaders;
      observe?: "body";
      params?: HttpParams;
      reportProgress?: boolean;
      responseType: "arraybuffer";
      withCredentials?: boolean;
    } = {
      responseType: "arraybuffer",
      headers,
    };

    return this.httpClient
      .cache(Object.assign(new CacheSettings(), { expires: 60 * 60 * 11 }), false)
      .get(`files/${fileId}`, options)
      .pipe(
        map((file: ArrayBuffer) => {
          switch (convertTo) {
            case "Blob":
              return new Blob([file]);
            case "any":
            case "Uint8Array":
            default:
              return new Uint8Array(file);
          }
        })
      );
  }

  // tslint:disable-next-line:max-line-length
  uploadFiles(
    fileContainers: FileContainerBase[],
    resource: string,
    entityId: string,
    resourceAndQueryParametersSelector: (fileContainer: FileContainerBase) => string
  ): Observable<DatabaseFile> {
    return new Observable((subscriber: Subscriber<any>) => {
      if (fileContainers.length === 0) {
        subscriber.complete();
        return;
      }
      this._uploadFiles(
        subscriber,
        fileContainers.filter((q) => q.state === FileState.New),
        0,
        resource,
        entityId,
        resourceAndQueryParametersSelector
      );
    });
  }

  getPresignedUrl(fileId: string, token?: string): Observable<string> {
    // use the default hypecast token if not provided. Most likely this will be the case in requests
    // from plugins like Staffbase and Haiilo
    if (__.IsNullOrUndefinedOrEmpty(token)) {
      return this.httpClient
        .disableApiPrefix()
        .cache(Object.assign(new CacheSettings(), { expires: 60 * 60 * 11 }), false)
        .post<Response<string>>(`${this.apiUrlService.getCdnUrl()}api/v1/files/${fileId}/urls`, null)
        .pipe(
          map((result: Response<string>) => {
            return result.data;
          })
        );
    }

    const httpClient = this.httpClient.disableAccessToken().disableApiPrefix();
    const headers = { Authorization: "Bearer " + token };

    return httpClient
      .cache(Object.assign(new CacheSettings(), { expires: 60 * 60 * 11 }), false)
      .post<Response<string>>(`${this.apiUrlService.getCdnUrl()}api/v1/files/${fileId}/urls`, null, { headers })
      .pipe(
        map((result: Response<string>) => {
          return result.data;
        })
      );
  }

  getPresignedUrlForIFrame(fileId: string): Observable<string> {
    const httpClient = this.httpClient.disableAccessToken().disableApiPrefix();

    return httpClient
      .cache(Object.assign(new CacheSettings(), { expires: 60 * 60 * 11 }), false)
      .post<Response<string>>(`${this.apiUrlService.getCdnUrl()}api/v1/files/${fileId}/iframe-urls`, null)
      .pipe(
        map((result: Response<string>) => {
          return result.data;
        })
      );
  }

  // tslint:disable-next-line:max-line-length
  private _uploadFiles(
    subscriber: Subscriber<any>,
    fileContainers: FileContainerBase[],
    fileContainerIndex: number,
    resource: string,
    entityId: string,
    resourceAndQueryParametersSelector: (fileContainer: FileContainerBase) => string
  ) {
    super.addSubscription(
      this.uploadFile(
        fileContainers[fileContainerIndex],
        resource,
        entityId,
        resourceAndQueryParametersSelector
      ).subscribe({
        next: (file: DatabaseFile) => {
          subscriber.next(file);
          if (__.IsNullOrUndefined(fileContainers[fileContainerIndex + 1])) {
            subscriber.complete();
          } else {
            this._uploadFiles(
              subscriber,
              fileContainers,
              fileContainerIndex + 1,
              resource,
              entityId,
              resourceAndQueryParametersSelector
            );
          }
        },
      })
    );
  }

  // tslint:disable-next-line:max-line-length
  private uploadFile(
    fileContainer: FileContainerBase,
    resource: string,
    entityId: string,
    resourceAndQueryParametersSelector: (fileContainer: FileContainerBase) => string
  ): Observable<DatabaseFile> {
    const formData = new FormData();

    formData.append("file", (fileContainer.original as any).file);

    return this.httpClient
      .post<Response<DatabaseFile>>(
        `${resource}/${entityId}/${resourceAndQueryParametersSelector(fileContainer)}`,
        formData
      )
      .pipe(
        map((result: Response<DatabaseFile>) => {
          return result.data;
        })
      );
  }
}
