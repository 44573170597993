import { Route as ngRoute, Routes } from '@angular/router';

import { AuthenticationGuard } from './authentication/authentication.guard';
import { BaseLayoutComponent } from './layouts/base/base-layout.component';

/**
 * Provides helper methods to create routes.
 */
export class Route {
    /**
     * Creates routes using the shell component and authentication.
     * @param routes The routes to add.
     * @return The new route using shell as the base.
     */
    static withBaseLayoutAndAuthentication(routes: Routes): ngRoute {
        return {
            path: '',
            component: BaseLayoutComponent,
            children: routes,
            canActivate: [AuthenticationGuard]
        };
    }
}
