import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { Route } from "./core/Routes";
import { AppRoute } from "./shared/app.route.enum";

const routes: Routes = [
  // The base layout and authentication takes care of authentication
  // for every request. Use the authentication guard on lower routing levels
  // to enable permission based authorization (see below)

  {
    path: "",
    redirectTo: `/${AppRoute.Home}`,
    pathMatch: "full",
  },
  // IFRAME PLUGIN ROUTES
  {
    path: AppRoute.IframePlugin,
    loadChildren: () => import("./iframe-plugin/iframe-plugin.module").then((m) => m.IframePluginModule),
  },

  // STAFFBASE PLUGIN ROUTES
  {
    path: AppRoute.StaffbasePlugin,
    loadChildren: () => import("./staffbase-plugin/staffbase-plugin.module").then((m) => m.StaffbasePluginModule),
  },
  {
    path: AppRoute.StaffbasePluginEditor,
    loadChildren: () => import("./staffbase-plugin/editor/editor.module").then((m) => m.EditorModule),
  },
  {
    path: AppRoute.StaffbasePluginUser,
    loadChildren: () => import("./staffbase-plugin/user/user.module").then((m) => m.UserModule),
  },

  // COYO PLUGIN ROUTES
  {
    path: AppRoute.CoyoPlugin,
    loadChildren: () => import("./coyo-plugin/coyo-plugin.module").then((m) => m.CoyoPluginModule),
  },

  Route.withBaseLayoutAndAuthentication([
    {
      path: AppRoute.Podcasts,
      loadChildren: () => import("./podcasts/podcasts.module").then((m) => m.PodcastsModule),
    },
    {
      path: AppRoute.Episodes,
      loadChildren: () => import("./episodes/episodes.module").then((m) => m.EpisodesModule),
    },
    {
      path: AppRoute.Discover,
      loadChildren: () => import("./discovery/discovery.module").then((m) => m.DiscoveryModule),
    },
    {
      path: AppRoute.Home,
      loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
    },
    {
      path: AppRoute.Profile,
      loadChildren: () => import("./profile/profile.module").then((m) => m.ProfileModule),
    },
    {
      path: AppRoute.Groups,
      loadChildren: () => import("./groups/groups.module").then((m) => m.GroupsModule),
    },
  ]),
  // {
  //   path: AppRoute.Login,
  //   loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  // },
  // {
  //   path: AppRoute.Register,
  //   loadChildren: () => import('./register/register.module').then(m => m.RegisterModule)
  // },
  {
    path: AppRoute.Settings,
    loadChildren: () => import("./settings/settings.module").then((m) => m.SettingsModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
