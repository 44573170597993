import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "minuteSeconds",
})
export class MinuteSecondsPipe implements PipeTransform {
  transform(value: number): string {
    const minutes = Math.floor(value / 60) || 0;
    const seconds = value - minutes * 60 || 0;

    return minutes.toString().padStart(2, "0") + ":" + (Math.trunc(seconds) < 10 ? "0" : "") + Math.trunc(seconds);
  }
}
