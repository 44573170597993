<div class="dialog-header">

  <div fxLayout="row" fxLayoutGap="24px">

    <!-- TITLE -->
    <h2 fxFlex="1 1 auto" class="dialog-header__title">
      {{ title }}
    </h2> <!-- /dialog-header__title -->

    <!-- OPTIONAL ACTION BUTTONS -->
    <div *ngIf="items.length > 0" fxFlex="0 0 auto" class="dialog-header__actions">
      <hypecast-generic-actions [items]="items"></hypecast-generic-actions>
    </div> <!-- /dialog-header__actions -->
    <div *ngIf="items.length === 0" fxFlex="0 0 auto" class="dialog-header__actions">
      <ng-content></ng-content>
    </div> <!-- /dialog-header__actions -->

    <!-- CLOSE ICON BUTTON -->
    <div fxFlex="0 1 auto" fxLayoutAlign="end" class="dialog-header__close-icon-button">
      <button mat-icon-button [matTooltip]="closeTooltipText" (click)="emitCloseClick.emit(true)">
        <mat-icon>close</mat-icon>
      </button>
    </div> <!-- /dialog-header__close-icon-button -->

  </div>

  <!-- DIVIDER -->
  <mat-divider *ngIf="dividerIsVisible" class="dialog-header__divider dialog-header__divider--stretched"></mat-divider>

</div>