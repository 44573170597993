import { HttpClient, HttpErrorResponse, HttpHandler, HttpHeaders } from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {BaseService} from '@app/shared/base/services';
import {__} from '@app/shared/functions/object.functions';
import {Role} from '@app/shared/models/classes/Role';
import {Tenant} from '@app/shared/models/classes/Tenant';
import {User} from '@app/shared/models/classes/User';
import {ApiUrlService} from '@app/shared/services/api-url.service';
import {ITenantService} from '@app/shared/services/itenant.service';
import {environment} from '@env/environment';
import {Observable, Subject, Subscriber} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {IOAuthService} from "@app/shared/authentication/angular-oauth-oidc/ioauth-service";

const credentialsKey = 'hypecast-credentials';

export class HypecastCredentials {
  user: User;
  data: {
    [key: string]: number | string | boolean;
  };
}

/**
 * Provides a base for authentication workflow.
 */
@Injectable()
export class HypecastAuthenticationService extends BaseService {

  private _credentials: HypecastCredentials | null;

  get credentials(): HypecastCredentials | null {
    return this._credentials;
  }

  private _credentials$: Subject<HypecastCredentials> = new Subject<HypecastCredentials>();

  credentials$: Observable<HypecastCredentials>;

  private httpClient: HttpClient;

  constructor(
    private httpHandler: HttpHandler,
    private oAuthService: IOAuthService,
    private tenantService: ITenantService,
    private apiUrlService: ApiUrlService
  ) {
    super();

    const injector = Injector.create(
      {
        providers: [
          {provide: HttpClient, useClass: HttpClient, deps: [HttpHandler]},
          {provide: HttpHandler, useValue: httpHandler}
        ]
      }
    );

    this.httpClient = injector.get(HttpClient);

    this.credentials$ = new Observable<HypecastCredentials>((subscriber: Subscriber<HypecastCredentials>) => {
      if (this.oAuthService.hasValidAccessToken()) {
        // User has a valid access token

        if (!__.IsNullOrUndefined(this._credentials)) {
          // If the credentials have already been set
          subscriber.next(this._credentials);
          return;
        } else {
          // No credentials have been set
          const item = JSON.parse(localStorage.getItem(credentialsKey)) as HypecastCredentials;

          if (!__.IsNullOrUndefined(item)) {
            // There are credentials in the storage
            // TODO: Potential for security breach, since stored credendtials could be old ones from a previous user?

            this._credentials = item;
            subscriber.next(this._credentials);
          } else {
            super.addSubscription(
              this._credentials$.subscribe({
                next: (credentials: HypecastCredentials) => {
                  subscriber.next(credentials);
                },
                error: (error: HttpErrorResponse) => {
                  subscriber.error(error);
                }
              })
            );
          }
        }

        return;
      }

      // No valid access token exists
      super.addSubscription(
        this._credentials$.subscribe({
          next: (credentials: HypecastCredentials) => {
            subscriber.next(credentials);
          },
          error: (error: HttpErrorResponse) => {
            subscriber.error(error);
          }
        })
      );
    });
  }

  init(): Observable<HypecastCredentials> {
    if (!this.oAuthService.hasValidAccessToken()) {
      return new Observable(s => {
        s.error({error: null, message: 'Cannot get user and permissions without valid access token'});
      });
    }

    super.addSubscription(
      this.tenantService.getTenant$()
        .pipe(
          switchMap((tenant: Tenant) => {
            return this.getCurrentUser();
          })
        )
        .subscribe({
          next: (data: User) => {
            if (!__.IsNullOrUndefined(this._credentials)) {
              // If the credentials have already been set
              this._credentials$.next(this._credentials);
              return;
            } else {
              // No credentials have been set
              const item = JSON.parse(localStorage.getItem(credentialsKey)) as HypecastCredentials;

              if (!__.IsNullOrUndefined(item)) {
                // There are credentials in the storage
                // TODO: Potential for security breach, since stored credentials could be old ones from a previous user?

                this._credentials = item;
                this._credentials$.next(this._credentials);
              } else {
                const credentials = Object.assign(new HypecastCredentials(), {
                  user: data,
                  data: this.oAuthService.getIdentityClaims()
                } as HypecastCredentials);

                this.saveToStorage(credentials);
                this._credentials = credentials;
                this._credentials$.next(credentials);
              }
            }
          },
          error: (error: HttpErrorResponse) => {
            this._credentials$.error(error);
          }
        })
    );

    return this.credentials$;
  }

  get user(): User {
    return this.credentials.user;
  }

  hasRole(group: string) {
    if (__.IsNullOrUndefinedOrEmpty(group)) {
      return true;
    }
    if (environment.testPermissions === false && environment.production !== true) {
      return true;
    }
    if (!__.IsNullOrUndefined(this.credentials.user.roles)) {
      if (this.isAdministrator()) {
        return true;
      }
      return this.credentials.user.roles.some(q => q === group);
    }
    return false;
  }

  hasAnyRole(groups: string[]) {
    if (groups.length === 0) {
      return true;
    }
    if (environment.testPermissions === false && environment.production !== true) {
      return true;
    }
    if (!__.IsNullOrUndefined(this.credentials.user.roles)) {
      if (this.isAdministrator()) {
        return true;
      }

      return groups.findIndex(a => this.credentials.user.roles.some(q => q === a)) > -1;
    }
    return false;
  }

  isAdministrator(): boolean {
    return this.credentials.user.roles.indexOf(Role.Administrator) > -1 || this.credentials.user.roles.indexOf(Role.CompanyAdministrator) > -1;
  }

  isAuthenticated(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }

  getAccessToken(): string {
    return this.oAuthService.getAccessToken();
  }

  logout(): void {
    sessionStorage.removeItem(credentialsKey);
    localStorage.removeItem(credentialsKey);
    return this.oAuthService.logOut();
  }


  private saveToStorage(credentials: HypecastCredentials): void {
    this._credentials = credentials || null;

    if (!__.IsNullOrUndefined(credentials)) {
      const storage = true ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }

  private getCurrentUser(): Observable<User> {
    let headers = new HttpHeaders();
    const token = this.oAuthService.getAccessToken();

    headers = headers.set('Authorization', `Bearer ${token}`);

    return this.httpClient.get<any>(
      `${this.apiUrlService.getApiUrl()}api/v1/users/me`,
      {headers}
    ).pipe(
      map((result: any) => {
        return result.data;
      })
    );
  }
}
