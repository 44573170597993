import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { DialogModule } from '@app/shared/components/dialog/dialog.module';
import { SharedModule } from '@app/shared/shared.module';

import { FormDeactivateableDialogComponent } from './form-deactivateable-dialog.component';

@NgModule({
  imports: [
    SharedModule,
    DialogModule,
    MatButtonModule
  ],
  declarations: [FormDeactivateableDialogComponent],
  exports: [FormDeactivateableDialogComponent]
})
export class FormDeactivateableDialogModule { }
