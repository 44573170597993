import {Injectable, OnDestroy} from "@angular/core";
import {AuthConfig} from "@app/shared/authentication/angular-oauth-oidc/auth.config";
import {IOAuthService} from "@app/shared/authentication/angular-oauth-oidc/ioauth-service";
import {Observable} from "rxjs";
import {
  IOAuthStorage,
  LoginOptions,
  OidcDiscoveryDoc,
  ParsedIdToken,
  TokenResponse,
  UserInfo
} from "@app/shared/authentication/angular-oauth-oidc/types";
import {OAuthEvent, OAuthSuccessEvent} from "@app/shared/authentication/angular-oauth-oidc/events";
import {ValidationHandler} from "@app/shared/authentication/angular-oauth-oidc/token-validation/validation-handler";
import { HttpHeaders } from "@angular/common/http";

@Injectable()
export class MockOAuthService extends AuthConfig implements OnDestroy, IOAuthService {
  constructor() {
    super();
  }
  discoveryDocumentLoaded: boolean;
  discoveryDocumentLoaded$: Observable<OidcDiscoveryDoc>;
  events: Observable<OAuthEvent> = new Observable<OAuthEvent>();
  tokenValidationHandler: ValidationHandler;
  config: AuthConfig;

  authorizationHeader(): string {
    return "";
  }

  checkSession(): void {
  }

  configure(config: AuthConfig): void {
  }

  createAndSaveNonce(): Promise<string> {
    return Promise.resolve("");
  }

  fetchTokenUsingPasswordFlow(userName: string, password: string, headers: HttpHeaders): Promise<TokenResponse> {
    return Promise.resolve(undefined);
  }

  fetchTokenUsingPasswordFlowAndLoadUserProfile(userName: string, password: string, headers: HttpHeaders): Promise<UserInfo> {
    return Promise.resolve(undefined);
  }

  getAccessToken(): string {
    return "";
  }

  getAccessTokenExpiration(): number {
    return 0;
  }

  getCustomTokenResponseProperty(requestedProperty: string): any {
  }

  getGrantedScopes(): object {
    return undefined;
  }

  getIdToken(): string {
    return "";
  }

  getIdTokenExpiration(): number {
    return 0;
  }

  getIdentityClaims(): object {
    return undefined;
  }

  getRefreshToken(): string {
    return "";
  }

  hasValidAccessToken(): boolean {
    return false;
  }

  hasValidIdToken(): boolean {
    return false;
  }

  initCodeFlow(additionalState: string, params: object): void {
  }

  initImplicitFlow(additionalState: string, params: string | object): void {
  }

  initImplicitFlowInPopup(options?: { height?: number; width?: number }): any {
  }

  initLoginFlow(additionalState: string, params: object): void {
  }

  initLoginFlowInPopup(options?: { height?: number; width?: number }): any {
  }

  loadDiscoveryDocument(fullUrl: string): Promise<OAuthSuccessEvent> {
    return Promise.resolve(undefined);
  }

  loadDiscoveryDocumentAndLogin(options: LoginOptions & { state?: string }): Promise<boolean> {
    return Promise.resolve(false);
  }

  loadDiscoveryDocumentAndTryLogin(options: LoginOptions): Promise<boolean> {
    return Promise.resolve(false);
  }

  loadUserProfile(): Promise<UserInfo> {
    return Promise.resolve(undefined);
  }

  logOut(): void;
  logOut(customParameters: object): void;
  logOut(noRedirectToLogoutUrl: boolean): void;
  logOut(noRedirectToLogoutUrl: boolean, state: string): void;
  logOut(customParameters: boolean | object, state: string): void;
  logOut(customParameters?: object | boolean, state?: string): void {
  }

  ngOnDestroy(): void {
  }

  processIdToken(idToken: string, accessToken: string, skipNonceCheck: boolean): Promise<ParsedIdToken> {
    return Promise.resolve(undefined);
  }

  refreshToken(): Promise<TokenResponse> {
    return Promise.resolve(undefined);
  }

  resetImplicitFlow(): void {
  }

  restartSessionChecksIfStillLoggedIn(): void {
  }

  revokeTokenAndLogout(customParameters: object, ignoreCorsIssues: boolean): Promise<any> {
    return Promise.resolve(undefined);
  }

  setStorage(storage: IOAuthStorage): void {
  }

  setupAutomaticSilentRefresh(params: object, listenTo?: "access_token" | "id_token" | "any"): void {
  }

  silentRefresh(params: object, noPrompt: boolean): Promise<OAuthEvent> {
    return Promise.resolve(undefined);
  }

  tryLogin(options: LoginOptions): Promise<boolean> {
    return Promise.resolve(false);
  }

  tryLoginCodeFlow(options: LoginOptions): Promise<void> {
    return Promise.resolve(undefined);
  }

  tryLoginImplicitFlow(options: LoginOptions): Promise<boolean> {
    return Promise.resolve(false);
  }
}
