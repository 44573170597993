import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { __ } from '@app/shared/functions/object.functions';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { FormDeactivateableDialogComponent } from './dialog/form-deactivateable-dialog.component';
import { IFormDeactivateable } from './IDeactivateable';

@Injectable()
export class FormDeactiviationGuard  {

    private isOpen: boolean = false;

    constructor(private dialog: MatDialog) { }

    canDeactivate(component: IFormDeactivateable): Observable<boolean> {
        if (!__.IsNullOrUndefined(component.hasPendingChanges) && this.isOpen === false && component.hasPendingChanges() === true) {
            this.isOpen = true;

            return this.dialog
                .open(FormDeactivateableDialogComponent)
                .afterClosed()
                .pipe(
                    finalize(() => {
                        this.isOpen = false;
                    }),
                    map((result: any) => {
                        if (!__.IsNullOrUndefined(result)) {
                            return true;
                        } else {
                            return false;
                        }
                    })
                )
        }
        return of(true);
    }

}