import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { DialogHeaderComponent } from './dialog-header.component';
import { GenericActionsModule } from '../../generic-actions/generic-actions.module';

/**
 * This module is responsible for the export of the mb dialog header component
 *
 * @export
 */
@NgModule({
  imports: [
    CommonModule,

    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatTooltipModule,

    GenericActionsModule
  ],
  declarations: [DialogHeaderComponent],
  exports: [DialogHeaderComponent]
})
export class DialogHeaderModule { }
