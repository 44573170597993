import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AuthorizedDirective } from './authorized.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [AuthorizedDirective],
  exports: [AuthorizedDirective]
})
export class AuthorizedModule { }
