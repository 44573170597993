import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { __ } from '@app/shared/functions/object.functions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HypecastAuthenticationService, HypecastCredentials } from '../services/authentication.service';

/**
 * The authentication guard makes sure that credentials have been fetched, before a
 * routing event is continued
 */
@Injectable()
export class HypecastCredentialsAuthenticationGuard  {

    constructor(
        private authenticationService: HypecastAuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.authenticationService.credentials$.pipe(map((value: HypecastCredentials) => !__.IsNullOrUndefined(value)));
    }
}
