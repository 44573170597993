import { Component, OnInit } from '@angular/core';
import { Animations } from '@app/shared/animations';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { __ } from '@app/shared/functions/object.functions';
import { MetricsService } from '@app/shared/services/metrics.service';

import { AudioPlayerService, Queue, QueueItem } from './audio-player.service';

@Component({
  selector: 'hypecast-audio-player-control',
  templateUrl: './audio-player-control.component.html',
  styleUrls: ['./audio-player-control.component.scss'],
  animations: Animations
})
export class AudioPlayerControlComponent extends BaseComponent implements OnInit {

  queue: Queue;

  currentItem: QueueItem;

  constructor(
    private audioPlayerService: AudioPlayerService,
    private metricsService: MetricsService
  ) {
    super();
  }

  ngOnInit() {
    super.addSubscription(
      this.audioPlayerService.queue$.subscribe((queue: Queue) => {
        this.queue = queue;

        const currentItem = queue.items[0];

        if (!__.IsNullOrUndefined(this.currentItem) && !__.IsNullOrUndefined(currentItem) && this.currentItem.src !== currentItem.src) {
          this.currentItem = currentItem;
        } else {
          this.currentItem = currentItem; // TODO:
        }
      })
    )
  }

  trackStream($event: boolean): void {
    if ($event === false) {
      this.metricsService
        .createNewStream(this.currentItem.episode.id)
        .subscribe({
          next: (result: boolean) => { }
        });
    }
  }

  trackListen(value: number): void {
    if (value > 0) {
      this.metricsService
        .createNewListen(this.currentItem.episode.id, String(value))
        .subscribe({
          next: (result: boolean) => { }
        });
    }
  }
}
