import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Tenant } from '../models/classes/Tenant';

@Injectable()
export abstract class ITenantService {

    abstract getTenant$(): Observable<Tenant>;

    abstract getTenant(): Tenant;

    abstract getTenantName(): string;
}
