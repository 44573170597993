import {BaseService} from "@app/shared/base/services";
import {IHypecastOAuthService} from "@app/shared/authentication/services/ihypecast-oauth.service";
import {Injectable} from "@angular/core";

@Injectable()
export class MockHypecastOAuthService extends BaseService implements IHypecastOAuthService {
  getAccessToken(): string {
    return "";
  }

  getAccessTokenExpiration(): number {
    return 0;
  }

  getIdToken(): string {
    return "";
  }

  getIdTokenExpiration(): number {
    return 0;
  }

  getIdentityClaims(): object {
    return undefined;
  }

  getRefreshToken(): string {
    return "";
  }

  hasValidAccessToken(): boolean {
    return false;
  }

  hasValidIdToken(): boolean {
    return false;
  }

  logOut(): void {
  }

  login(): Promise<any> {
    return Promise.resolve(undefined);
  }

}
