import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BreadcrumbsComponent } from './breadcrumbs.component';
import { CommonModule } from '@angular/common';
import { BreadcrumbsService } from './breadcrumbs.service';

/**
 * This module represents the breadcrumb display and service
 *
 */
@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [BreadcrumbsComponent],
  exports: [BreadcrumbsComponent]
})
export class BreadcrumbsModule {}
