import { HttpClient, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger } from '@app/core/logger.service';
import { ResetPasswordBody, User, UserCreateDto, UserUpdateDto } from '@app/shared/models/classes/User';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IOAuthService } from '../authentication/angular-oauth-oidc/ioauth-service';
import { LoadingState } from '../components/files/models/LoadingState.enum';
import { ApiUrlService } from './api-url.service';
import { EntityService } from './entity.service';
import { Response } from './local/Response';

const log = new Logger('Users Service');

@Injectable()
export class UsersService extends EntityService<User, UserCreateDto, UserUpdateDto> {

  constructor(
    protected httpClient: HttpClient,
    private apiUrlService: ApiUrlService,
    private oAuthService: IOAuthService,
  ) {
    super('users', 'User', httpClient);
  }

  getCurrentUser(): Observable<User> {
    return this.httpClient.get<any>(`${this.apiUrlService.getApiUrl()}users/me`).pipe(
      map((result: any) => {
        return result.data;
      })
    );
  }

  getMentionableUsers(searchString: string): Observable<User[]> {
    // TODO: Make autoqueryable
    return this.httpClient.get<any>(`users?filter=${searchString}&isMentionable=true`).pipe(
      map((result: any) => {
        return result.data;
      })
    );
  }

  changePassword(currentPassword: string, newPassword: string): Observable<boolean> {
    let headers = new HttpHeaders();
    const token = this.oAuthService.getAccessToken();

    headers = headers.set('Authorization', `Bearer ${token}`);

    return this.httpClient
      .disableApiPrefix()
      .patch<Response<boolean>>(`${environment.authorizationServerUrl}api/v1/users/me/password`, {
        currentPassword,
        password: newPassword
      }, { headers })
      .pipe(
        map((result: Response<boolean>) => {
          return result.code.statusCode === 200;
        })
      );
  }

  resetPassword(userId: string, resetPasswordDto: ResetPasswordBody) {
    return this.httpClient.patch<any>(
      `users/${userId}/password`,
      resetPasswordDto
    ).pipe(
      map((result: Response<boolean>) => {
        return result.code.statusCode === 200;
      })
    );
  }

  sendPasswordResetEmail(email: string) {

    return this.httpClient
      .post<any>(
        `users/password-reset`, { email }
      ).pipe(
        map((result: Response<boolean>) => {
          return result.code.statusCode === 200;
        })
      );
  }

  changePermissions(userId: string, permissions: string[], useCaseIds: string[]): Observable<boolean> {
    return this.httpClient
      .put<Response<boolean>>(`users/${userId}/permissions`, { permissions, useCaseIds })
      .pipe(
        map((result: Response<boolean>) => {
          return result.data;
        })
      );
  }

  getPermissions(userId: string): Observable<GetUserPermission> {
    return this.httpClient.get<Response<GetUserPermission>>(`users/${userId}/permissions`).pipe(
      map((result: Response<GetUserPermission>) => {
        return result.data;
      })
    );
  }

  completeRegistration(userId: string, token: string, value: any): Observable<string> {
    return this.httpClient
      .disableAccessToken()
      .post(
        `users/${userId}/confirm-email`,
        Object.assign({ password: value, token })
      )
      .pipe(
        map((result: Response<string>) => {
          return result.data;
        })
      );
  }


  // https://stackoverflow.com/questions/46206643/asp-net-core-2-0-and-angular-4-3-file-upload-with-progress0
  /**
   * Sets the specified users profile image by streaming it to the backend. The progress is reported back
   * and emitted in the value of the return observable.
   *
   * @param userId The id of the user whose profile image should be changed
   * @param image The image that should be uploaded
   */
  changeProfileImageByUserId(userId: string, image: Blob): Observable<ChangeProfilePictureProgress> {
    const formData = new FormData();
    formData.append('file', image);

    return this.httpClient
      .post(`users/${userId}/images`, formData, { reportProgress: true, observe: 'events' })
      .pipe(
        map((event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            return new ChangeProfilePictureProgress(Math.round(100 * event.loaded / event.total), LoadingState.Loading);
          } else if (event instanceof HttpResponse) {
            return new ChangeProfilePictureProgress(100, LoadingState.Completed);
          }
          return new ChangeProfilePictureProgress(0, LoadingState.Querying);
        })
      )
  }
}

export class ChangeProfilePictureProgress {
  progress: number = 0;

  state: LoadingState = LoadingState.Initial;

  constructor(_progress: number, _state: LoadingState) {
    this.progress = _progress;
    this.state = _state;
  }
}
export class GetUserPermission {
  permissions: string[] = [];
  useCaseIds: string[] = [];
}