export class BaseModel {
  id: string;

  created: string;

  createdBy: string;

  createdByName: string;

  modified: string;

  modifiedBy: string;
}
