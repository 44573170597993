export abstract class IHypecastOAuthService {

  abstract getIdentityClaims(): object;

  abstract getIdToken(): string;

  /**
   * Returns the current access_token.
   */
  abstract getAccessToken(): string;

  /**
   * Returns the current refresh_token.
   */
  abstract getRefreshToken(): string;

  /**
   * Returns the expiration date of the access_token
   * as milliseconds since 1970.
   */
  abstract getAccessTokenExpiration(): number;

  /**
   * Returns the expiration date of the id_token
   * as milliseconds since 1970.
   */
  abstract getIdTokenExpiration(): number;

  /**
   * Checkes, whether there is a valid access_token.
   */
  abstract hasValidAccessToken(): boolean;

  /**
   * Checks whether there is a valid id_token.
   */
  abstract hasValidIdToken(): boolean;

  /**
   * Retrieve a saved custom property of the TokenReponse object. Only if predefined in authconfig.
   */

  abstract logOut(): void;

  abstract login(): Promise<any>;

}
