import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { DialogModule } from '../dialog/dialog.module';
import { ConfigurableTableDialogComponent } from './configurable-table-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    DragDropModule,
    DialogModule,
    MatButtonModule
  ],
  declarations: [ConfigurableTableDialogComponent],
  exports: [ConfigurableTableDialogComponent]
})
export class ConfigurableTableDialogModule { }
