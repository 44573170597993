<hypecast-dialog-header [title]="'Dialog.You have pending changes' | translate" class="dialog-header" (emitCloseClick)="dialogRef.close(null)">
</hypecast-dialog-header>

<div style="margin-top: 24px; min-width: 500px; margin-bottom: 24px;">
  {{ "Dialog.Are you sure to leave the page without saving" | translate }}
</div>

<hypecast-dialog-footer>
  <div fxLayout style="place-content: space-between;">
    <div fxFlex="0 1 auto">
      <button mat-stroked-button (click)="dialogRef.close(null)">{{ "Dialog.Continue editing" | translate }}</button>
    </div>
    <div fxFlex="0 1 auto">
      <button mat-flat-button color="primary" (click)="dialogRef.close(true)">{{ "Dialog.Leave without saving" | translate }}</button>
    </div>
  </div>
</hypecast-dialog-footer>