<ng-container *ngIf="IsNullOrUndefined(tenant)">
  <div class="loading-container">
    <div class="content">
      <!-- FORM -->
      <div class="items-center content-center">
        <img class="hypecast-logo" src="../../assets/hypecast-logo.png" style="height: 110px;"/>
        <h2 class="loading"><strong>Loading</strong></h2>
      </div>
      <div class="spinner">
        <mat-spinner [diameter]="25"></mat-spinner>
      </div>
    </div>
  </div>
</ng-container>
<router-outlet *ngIf="!IsNullOrUndefined(tenant)"></router-outlet>
