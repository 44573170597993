import { BaseModel } from './BaseModel';
import { Category } from './Category';
import { DatabaseFile } from './File';
import { Season } from './Season';


export class Podcast extends BaseModel {
  title: string;
  imageId: string;
  imageLargeId: string;
  kind: PodcastKind;
  type: PodcastType;
  isPublished: boolean;
  validFrom: string;
  validTo: string;
  publishedAt: string;
  description: string;
  externalSourceLink: string;
  externalImageLink: string;
  language: string;
  copyright: string;
  isExplicit: boolean;
  author: string;
  isCompleted: boolean;
  country: string;
  keywords: string[] = [];
  image: DatabaseFile;
  imageLarge: DatabaseFile;
  seasons: Season[] = [];
  categories: Category[] = [];
  subtitle: string;
  fullEpisodeCount: number;
}

export enum PodcastKind {
  Episodic = 'Episodic',
  Serial = 'Serial',
}

export enum PodcastType {
  Internal = 'Internal',
  ExternalMixedSources = 'ExternalMixedSources',
  ExternalSingleSource = 'ExternalSingleSource'
}

export class PodcastCreateDto {
  title: string;
}

export class PodcastUpdateDto {
  title: string;
}
