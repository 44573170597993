import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { I18nService } from './core/i18n.service';
import { AppRoute } from './shared/app.route.enum';
import { HypecastAuthenticationModule } from './shared/authentication/authentication.module';
import { HypecastBaseOAuthConfig } from './shared/authentication/models/HypecastBaseOAuthConfig';
import { HYPECAST_OAUTH_CONFIG } from './shared/authentication/tokens/hypecast-oauth-config.token';
import { APP_ROUTE, APP_TITLE, BreadcrumbsService } from './shared/components/breadcrumbs/breadcrumbs.service';
import { ConfigurableTableDialogModule } from './shared/components/configurable-table-dialog/configurable-table-dialog.module';
import { LoadingBarModule } from './shared/services/local/loading-bar/loading-bar.module';

registerLocaleData(localeDe);
registerLocaleData(localeEn);

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY'
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

export function getCurrentLanguage(i18nService: I18nService) { return i18nService.language; }

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserAnimationsModule,
        BrowserModule,
        LoadingBarModule.forRoot(),
        FormsModule,
        HttpClientModule,
        MatProgressSpinnerModule,
        CoreModule,
        SharedModule,
        TranslateModule.forRoot(),
        MomentDateModule,
        MatDatepickerModule,
        ConfigurableTableDialogModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        HypecastAuthenticationModule.forRoot(),
        AppRoutingModule], providers: [
        BreadcrumbsService,
        CurrencyPipe,
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        { provide: APP_ROUTE, useValue: AppRoute },
        { provide: APP_TITLE, useValue: 'Hypecast' },
        { provide: LOCALE_ID, useFactory: getCurrentLanguage, deps: [I18nService] },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        { provide: HYPECAST_OAUTH_CONFIG, useValue: new HypecastBaseOAuthConfig('test') },
    ] })
export class AppModule { }
