import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

import { BaseService } from '../base/services';
import { __ } from '../functions/object.functions';
import { Tenant } from '../models/classes/Tenant';

@Injectable()
export class ApiUrlService extends BaseService {

    constructor() {
        super();
    }

    getTenant(): Tenant {
        throw new Error('[API URL SERVICE] getTenant() has not been overriden');
    }

    getApiUrl(): string {
        if (environment.environmentName === 'DEV') {
            return environment.serverUrl;
        }

        const tenant = this.getTenant();

        if (!__.IsNullOrUndefined(tenant)) {
            return tenant.apiUrl;
        }

        throw new Error(`[API URL SERVICE] No tenant specified`);
    }

    getCdnUrl(): string {
        if (environment.environmentName === 'DEV') {
            return environment.serverUrlCDN;
        }

        const tenant = this.getTenant();

        if (!__.IsNullOrUndefined(tenant)) {
            return tenant.cdnUrl;
        }

        throw new Error(`[API URL SERVICE] No tenant specified`);
    }
}