import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MinuteSecondsPipe } from './minute-seconds.pipe';

/**
 * The pipes module exports custom pipes
 *
 * @export
 */
@NgModule({
    imports: [CommonModule],
    declarations: [MinuteSecondsPipe],
    exports: [MinuteSecondsPipe]
})
export class MinuteSecondsPipeModule { }
