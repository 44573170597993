import { Injectable } from '@angular/core';
import { AuthConfig } from "@app/shared/authentication/angular-oauth-oidc/auth.config";
import { HttpHeaders } from "@angular/common/http";
import {
  IOAuthStorage,
  LoginOptions,
  OidcDiscoveryDoc,
  ParsedIdToken,
  TokenResponse,
  UserInfo
} from "@app/shared/authentication/angular-oauth-oidc/types";
import { ValidationHandler } from "@app/shared/authentication/angular-oauth-oidc/token-validation/validation-handler";
import { Observable } from "rxjs";
import { OAuthEvent, OAuthSuccessEvent } from "@app/shared/authentication/angular-oauth-oidc/events";

@Injectable()
export abstract class IOAuthService extends AuthConfig {
  constructor() {
    super();
  }
  // Extending AuthConfig is just for LEGACY reasons
  // to not break existing code.

  /**
   * The ValidationHandler used to validate received
   * id_tokens.
   */
  abstract tokenValidationHandler: ValidationHandler;

  /**
   * @internal
   * Deprecated:  use property events instead
   */
  abstract discoveryDocumentLoaded:any;

  /**
   * @internal
   * Deprecated:  use property events instead
   */
  abstract discoveryDocumentLoaded$: Observable<OidcDiscoveryDoc>;

  /**
   * Informs about events, like token_received or token_expires.
   * See the string enum EventType for a full list of event types.
   */
  abstract events: Observable<OAuthEvent>;

  /**
   * The received (passed around) state, when logging
   * in with implicit flow.
   */
  abstract state?:any;

  abstract config: AuthConfig;

  /**
   * Use this method to configure the service
   * @param config the configuration
   */
  abstract configure(config: AuthConfig): void;


  abstract restartSessionChecksIfStillLoggedIn(): void;

  /**
   * Will setup up silent refreshing for when the token is
   * about to expire. When the user is logged out via this.logOut method, the
   * silent refreshing will pause and not refresh the tokens until the user is
   * logged back in via receiving a new token.
   * @param params Additional parameter to pass
   * @param listenTo Setup automatic refresh of a specific token type
   */
  abstract setupAutomaticSilentRefresh(
    params: object,
    listenTo?: 'access_token' | 'id_token' | 'any',
  ): void;


  /**
   * Convenience method that first calls `loadDiscoveryDocument(...)` and
   * directly chains using the `then(...)` part of the promise to call
   * the `tryLogin(...)` method.
   *
   * @param options LoginOptions to pass through to `tryLogin(...)`
   */
  abstract loadDiscoveryDocumentAndTryLogin(
    options: LoginOptions
  ): Promise<boolean>;

  /**
   * Convenience method that first calls `loadDiscoveryDocumentAndTryLogin(...)`
   * and if then chains to `initLoginFlow()`, but only if there is no valid
   * IdToken or no valid AccessToken.
   *
   * @param options LoginOptions to pass through to `tryLogin(...)`
   */
  abstract loadDiscoveryDocumentAndLogin(
    options: LoginOptions & { state?: string }
  ): Promise<boolean>;


  /**
   * DEPRECATED. Use a provider for OAuthStorage instead:
   *
   * { provide: OAuthStorage, useFactory: oAuthStorageFactory }
   * export function oAuthStorageFactory(): OAuthStorage { return localStorage; }
   * Sets a custom storage used to store the received
   * tokens on client side. By default, the browser's
   * sessionStorage is used.
   * @ignore
   *
   * @param storage
   */
  abstract setStorage(storage: IOAuthStorage): void;

  /**
   * Loads the discovery document to configure most
   * properties of this service. The url of the discovery
   * document is infered from the issuer's url according
   * to the OpenId Connect spec. To use another url you
   * can pass it to to optional parameter fullUrl.
   *
   * @param fullUrl
   */
  abstract loadDiscoveryDocument(
    fullUrl?: string,
  ): Promise<OAuthSuccessEvent>;

  /**
   * Uses password flow to exchange userName and password for an
   * access_token. After receiving the access_token, this method
   * uses it to query the userinfo endpoint in order to get information
   * about the user in question.
   *
   * When using this, make sure that the property oidc is set to false.
   * Otherwise stricter validations take place that make this operation
   * fail.
   *
   * @param userName
   * @param password
   * @param headers Optional additional http-headers.
   */
  abstract fetchTokenUsingPasswordFlowAndLoadUserProfile(
    userName: string,
    password: string,
    headers: HttpHeaders,
  ): Promise<UserInfo>;

  /**
   * Loads the user profile by accessing the user info endpoint defined by OpenId Connect.
   *
   * When using this with OAuth2 password flow, make sure that the property oidc is set to false.
   * Otherwise stricter validations take place that make this operation fail.
   */
  abstract loadUserProfile(): Promise<UserInfo>;

  /**
   * Uses password flow to exchange userName and password for an access_token.
   * @param userName
   * @param password
   * @param headers Optional additional http-headers.
   */
  abstract fetchTokenUsingPasswordFlow(
    userName: string,
    password: string,
    headers: HttpHeaders,
  ): Promise<TokenResponse>;

  /**
   * Refreshes the token using a refresh_token.
   * This does not work for implicit flow, b/c
   * there is no refresh_token in this flow.
   * A solution for this is provided by the
   * method silentRefresh.
   */
  abstract refreshToken(): Promise<TokenResponse>;

  /**
   * Performs a silent refresh for implicit flow.
   * Use this method to get new tokens when/before
   * the existing tokens expire.
   */
  abstract silentRefresh(
    params: object,
    noPrompt: boolean
  ): Promise<OAuthEvent>;

  /**
   * This method exists for backwards compatibility.
   * {@link OAuthService#initLoginFlowInPopup} handles both code
   * and implicit flows.
   */
  abstract initImplicitFlowInPopup(options?: {
    height?: number;
    width?: number;
  }): any;

  abstract initLoginFlowInPopup(options?: { height?: number; width?: number }): any;

  abstract checkSession(): void;


  /**
   * Starts the implicit flow and redirects to user to
   * the auth servers' login url.
   *
   * @param additionalState Optional state that is passed around.
   *  You'll find this state in the property `state` after `tryLogin` logged in the user.
   * @param params Hash with additional parameter. If it is a string, it is used for the
   *               parameter loginHint (for the sake of compatibility with former versions)
   */
  abstract initImplicitFlow(
    additionalState: string,
    params: string | object
  ): void;

  /**
   * Reset current implicit flow
   *
   * @description This method allows resetting the current implict flow in order to be initialized again.
   */
  abstract resetImplicitFlow(): void;

  /**
   * Delegates to tryLoginImplicitFlow for the sake of competability
   * @param options Optional options.
   */
  abstract tryLogin(options?: LoginOptions): Promise<boolean>;

  abstract tryLoginCodeFlow(options: LoginOptions): Promise<void>;

  /**
   * Checks whether there are tokens in the hash fragment
   * as a result of the implicit flow. These tokens are
   * parsed, validated and used to sign the user in to the
   * current client.
   *
   * @param options Optional options.
   */
  abstract tryLoginImplicitFlow(options: LoginOptions): Promise<boolean>;

  /**
   * @ignore
   */
  abstract processIdToken(
    idToken: string,
    accessToken: string,
    skipNonceCheck: boolean
  ): Promise<ParsedIdToken>;

  /**
   * Returns the received claims about the user.
   */
  abstract getIdentityClaims(): object;

  /**
   * Returns the granted scopes from the server.
   */
  abstract getGrantedScopes(): object;

  /**
   * Returns the current id_token.
   */
  abstract getIdToken(): string;

  /**
   * Returns the current access_token.
   */
  abstract getAccessToken(): string;

  abstract getRefreshToken(): string;

  /**
   * Returns the expiration date of the access_token
   * as milliseconds since 1970.
   */
  abstract getAccessTokenExpiration(): number;

  /**
   * Returns the expiration date of the id_token
   * as milliseconds since 1970.
   */
  abstract getIdTokenExpiration(): number;

  /**
   * Checkes, whether there is a valid access_token.
   */
  abstract hasValidAccessToken(): boolean;

  /**
   * Checks whether there is a valid id_token.
   */
  abstract hasValidIdToken(): boolean;

  /**
   * Retrieve a saved custom property of the TokenReponse object. Only if predefined in authconfig.
   */
  abstract getCustomTokenResponseProperty(requestedProperty: string): any;

  /**
   * Returns the auth-header that can be used
   * to transmit the access_token to a service
   */
  abstract authorizationHeader(): string;

  /**
   * Removes all tokens and logs the user out.
   * If a logout url is configured, the user is
   * redirected to it with optional state parameter.
   * @param noRedirectToLogoutUrl
   * @param state
   */
  abstract logOut(): void;
  abstract logOut(customParameters: object): void;
  abstract logOut(noRedirectToLogoutUrl: boolean): void;
  abstract logOut(noRedirectToLogoutUrl: boolean, state: string): void;
  abstract logOut(customParameters: boolean | object, state: string): void;

  /**
   * @ignore
   */
  abstract createAndSaveNonce(): Promise<string>;

  /**
   * @ignore
   */
  abstract ngOnDestroy(): void;

  /**
   * Start the implicit flow or the code flow,
   * depending on your configuration.
   */
  abstract initLoginFlow(additionalState: string, params: object): void;

  /**
   * Starts the authorization code flow and redirects to user to
   * the auth servers login url.
   */
  abstract initCodeFlow(additionalState?: string, params?: object): void;

  /**
   * Revokes the auth token to secure the vulnarability
   * of the token issued allowing the authorization server to clean
   * up any security credentials associated with the authorization
   */
  abstract revokeTokenAndLogout(
    customParameters: object,
    ignoreCorsIssues: boolean
  ): Promise<any>;
}
