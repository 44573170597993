import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';

import { DialogFooterComponent } from './dialog-footer.component';

/**
 * This module is responsible for the export of the mb dialog footer component
 *
 * @export
 */
@NgModule({
  imports: [
    CommonModule,

    MatDividerModule
  ],
  declarations: [DialogFooterComponent],
  exports: [DialogFooterComponent]
})
export class DialogFooterModule { }
